/* -------------- medical RWD --------------*/
@media (max-width: $w_pc_b){ 
    .page-medical{
        .medical-item{
            grid-template-columns: 45% auto;
            .content{
                padding: 20px 25px;
            }
        }
    }
}

@media (max-width: $w_pc){ 
    .page-medical{
        .medical-item{
            grid-template-columns: 40% auto;
            .content{
                padding: 18px 20px;
            }
            .main-content{
                margin-top: 10px;
            }
            .medical-title{
                font-size: 1.0625rem /* 17/16 */;
            }
            .des{
                font-size: .9375rem /* 15/16 */;
                margin-top: 8px;
            }
            .more{
                margin-top: 10px;
            }
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-medical{
        .medical-grid{
            gap: 30px 25px;
        }
    }
}

@media (max-width: $w_tb){ 
    .page-medical{
        .medical-grid{
            grid-template-columns: 1fr;
        }
        .medical-item{
            grid-template-columns: 165px auto;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-medical{
        .medical-item{
            align-items: flex-start;
            background: none;
            grid-template-columns: 125px auto;
            .content{
                padding: 0 15px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }