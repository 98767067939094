/* -------------- layout --------------*/
* {
	box-sizing: border-box;
	outline: none;
	word-break: break-word;
}

html, body{
	@include maw(1920px);
	padding: 0;
	margin: 0 auto;
}

body{
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.hide{
	display: none !important;
}

.clearfix{
	@extend %clearfix;
}

a, button{
	text-decoration: none;
	transition: all .4s ease;
}

.unreset{
	@extend %clearfix;
	line-height: initial;
	a{
		text-decoration: underline;
		transition: none;
	}
	img{
		max-width: 100% !important;
		height: auto !important; 
	}
	.oembed-provider-youtube {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		overflow: hidden;
		iframe, object, embed{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
		}
	}
	p{
		font-size: 1rem /* 16/16 */;
		line-height: 1.6;
	}
}

.font-serif{
	font-family: 'Noto Serif TC', 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
}

.img-limit{
	position: relative;
	&.banner-img{
		@include fiximg(100%, calc(720 / 1920 * 100%));
	}
	&.news-img{
		@include fiximg(100%, calc(285 / 300 * 100%));
	}
	&.medical-img{
		@include fiximg(100%, calc(260 / 260 * 100%));
	}
	&.outlink-img{
		@include fiximg(100%, calc(65 / 187 * 100%));
	}
	&.wsava-img{
		@include fiximg(100%, calc(195 / 390 * 100%));
	}
	&.calendar-img{
		@include fiximg(100%, calc(300 / 400 * 100%));
	}
}

.slick-slider{
	line-height: 0;
	position: relative;
	.slick-arrow{
		@include abs_pos($t:50%);
		@include size(35px);
		@include flex($ai:center, $jfc:center);
		background: var(--c-white);
		border-radius: 50%;
		cursor: pointer;
		&.slick-prev{
			left: 0;
			transform: translate(-100%, -50%);
		}
		&.slick-next{
			right: 0;
			transform: translate(100%, -50%);
		}
		&:hover{
			background: var(--c-primary);
			svg{
				color: var(--c-white);
			}
		}
		svg{
			color: var(--c-primary);
			font-size: 1rem /* 16/16 */;
			line-height: 1;
		}
	}
}
.slick-dotted{
	@extend %clearStyle;
	.slick-dots{
		@include abs_pos($b:10px);
		display: flex;
		justify-content: center;
		width: 100%;
		gap: 0 5px;
		li{
			@include size(14px);
			position: relative;
			transform: scale(.7);
			&.slick-active{
				transform: scale(1);
				button{
					background: none;
				}
			}
		}
		button{
			@include size(100%);
			@include abs_pos($t:0, $l:0);
			background: var(--c-font);
			border: 1px solid var(--c-font);
			border-radius: 50%;
			font-size: 0;
		}
	}
}

.page-banner{
	img{
		display: block;
		width: 100%;
	}
}
.breadcrumb-row{
	@extend %clearStyle;
	padding: 10px 0;
	.breadcrumb{
		@include flex($ai:baseline);
	}
	li{
		&:not(:last-child):after{
			content: '/';
			color: #bfbfbf;
			display: inline-block;
			font-size: .875rem /* 14/16 */;
			padding: 0 5px;
			line-height: 1.2;
		}
		&.home{
			padding-left: 20px;
			position: relative;
			&:before{
				@include abs_pos($t:4px, $l:0);
				content: '\f1b0';
				color: #bfbfbf;
				font-size: .875rem /* 14/16 */;
				font-weight: bold;
				font-family: 'Font Awesome 6 Free';
				line-height: 1.2;
			}
		}
	}
	a, p{
		color: #bfbfbf;
		display: inline-block;
		font-size: .875rem /* 14/16 */;
		line-height: 1.2;
	}
	a:hover{
		color: var(--c-success);
	}
}

.page-main-head{
	border-bottom: 1px solid var(--c-border);
	margin-bottom: 40px;
	.page-main-title{
		color: var(--c-font);
		display: inline-block;
		font-size: 1.5rem /* 24/16 */;
		font-weight: bold;
		line-height: 1.2;
		padding: 10px 15px;
		position: relative;
		margin-bottom: -2px;
		margin-top: 0;
		&:after{
			@include abs_pos($l:0, $b:0);
			@include size(120px, 2px);
			background: var(--c-success);
			content: '';
		}
	}
}

.page-info-list{
	@include flex;
	margin-top: -20px;
	margin-bottom: 30px;
	gap: 5px 0;
	.info-item{
		align-items: flex-start;
		display: grid;
		grid-template-columns: 17px auto;
		gap: 5px;
		&.link-item{
			align-items: baseline;
			svg, a{
				color: var(--c-primary);
				font-size: .9375rem /* 15/16 */;
				line-height: 1.2;
			}
			a{
				text-decoration: underline;
				text-underline-offset: 3px;
				&:hover{
					color: var(--c-success);
				}
			}
		}
		&:not(:last-child){
			padding-right: 20px;
			position: relative;
			&:after{
				@include abs_pos($t:-2px, $r:7px);
				content: '/';
				color: #aaa;
				font-size: .9375rem /* 15/16 */;
			}
		}
		svg, span{
			color: #aaa;
			font-size: .9375rem /* 15/16 */;
			line-height: 1.2;
		}
	}
}

.page-relative-row{
	border-top: 1px solid var(--c-border);
	margin-top: 55px;
	padding-top: 40px;
	position: relative;
	min-height: 120px;
	.back-btn{
		border-radius: 3px;
		background: var(--c-success);
		color: var(--c-white);
		display: block;
		font-size: 1rem /* 16/16 */;
		line-height: 1;
		padding: 15px 8px;
		text-align: center;
		max-width: 140px;
		margin: auto;
		&:hover{
			background: var(--c-primary);
		}
	}
	.relative-item{
		@include abs_pos($t:40px);
		width: calc((100% - 140px) / 2 - 40px);
		.relative-link{
			color: var(--c-fontLight);
			font-size: 1rem /* 16/16 */;
			line-height: 1.3;
			&:hover{
				color: var(--c-success);
			}
		}
		.des{
			color: var(--c-primary);
			font-size: 1rem /* 16/16 */;
			font-weight: bold;
			margin: 0;
			margin-top: 10px;
		}
	}
	.prev-item{
		left: 0;
	}
	.next-item{
		text-align: right;
		right: 0;
	}
}

.sns-row{
	margin-top: 30px;
	.a2a_kit{
		display: flex;
		gap: 0 5px;
		.a2a_button_facebook svg{
			color: #316FF6;
		}
		.a2a_button_line svg{
			color: #00b900;
		}
		.a2a_button_x svg{
			color: #000;
		}
		.a2a_button_copy_link svg{
			color: var(--c-success);
		}
		a{
			@include size(30px);
			align-items: center;
			border-radius: 50%;
			justify-content: center;
			display: inline-flex;
			&:hover{
				opacity: .6;
			}
			svg{
				font-size: 1.5rem /* 24/16 */;
				line-height: 1;
			}
		}
	}
}

.pop-sec{
	@include size(100vw, 100vh);
	background: rgba(black, .5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: none;
	.pop-box{
		@include size(100%);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
	}
	.pop-content{
		background: var(--c-white);
		border-radius: 5px;
		position: relative;
		padding: max(5vw, 40px) max(10vw, 30px);
		overflow: hidden;
		.btn-close{
			@include abs_pos($t:0, $r:0);
			@include flex($ai:center, $jfc:center);
			@include size(40px);
			background: var(--c-success);
			&:hover{
				background: var(--c-primary);
			}
			svg{
				color: var(--c-white);
				font-size: 1.25rem /* 20/16 */;
			}
		}
	}
	.btn-row{
		justify-content: center;
		margin-top: 25px;
		button.submit{
			max-width: 250px;
		}
	}
}

.booked-search-pop-sec{
	.is-member-row{
		margin-top: 15px;
		text-align: center;
		p{
			color: var(--c-font);
			font-size: 1rem /* 16/16 */;
		}
		a{
			color: var(--c-primary);
			display: inline-block;
			text-decoration: underline;
			margin-left: 7px;
		}
	}
}

.table-style{
	@extend %clearStyle;
	table{
		border-collapse: collapse;
		width: 100%;
		.th, .td{
			border: 1px solid #dcdcdc;
			color: var(--c-font);
			font-size: 1rem /* 16/16 */;
			line-height: 1.4;
			padding: 15px 20px;
		}
		.th{
			background: #fdeeed;
			text-align: center;
			font-weight: bold;
		}
		a{
			color: var(--c-primary);
			font-size: 1rem /* 16/16 */;
			line-height: 1.4;
			&:hover{
				color: var(--c-success);
			}
		}
	}
}

.pop-wrapper{
	@extend %clearStyle;
	@include size(100vw, 100vh);
	@include flex($ai:center, $jfc:center);
	background: rgba(black, .85);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: none;
	&.open{
		display: flex;
	}
	.pop-box{
		background: var(--c-white);
		padding: 25px max(20px, 4.5vw);
		position: relative;
		overflow-y: auto;
		width: min(1200px, calc(100vw - 20px));
		max-height: 96vh;
	}
	.close-btn{
		@include abs_pos($t:0, $r:0);
		@include size(40px);
		@include flex($ai:center, $jfc:center);
		background: var(--c-success);
		&:hover{
			background: var(--c-primary);
		}
		svg{
			font-size: .875rem /* 14/16 */;
			color: var(--c-white);
		}
	}
	.print-btn{
		background: var(--c-primary);
		color: var(--c-white);
		display: block;
		padding: 12px;
		text-align: center;
		margin: 15px auto 0;
		width: 260px;
		&:hover{
			background: var(--c-success);
		}
	}
}

.receipt-wrapper{
	.logo{
		display: block;
		margin: auto;
		width: 400px;
	}
	.receipt-table{
		overflow-x: auto;
	}
	.table-head{
		align-items: baseline;
		display: flex;
		justify-content: space-between;
		padding-top: 15px;
		p{
			color: var(--c-font);
			font-size: 1.125rem /* 18/16 */;
			line-height: 1;
		}
		.receipt-no span{
			color: var(--c-danger);
			padding-left: 3px;
		}
		.receipt-title{
			color: var(--c-font);
			font-size: 2rem /* 32/16 */;
			font-weight: bold;
			letter-spacing: 15px;
		}
		.date span{
			display: inline-block;
			padding-left: 8px;
			padding-right: 8px;
		}
	}
	.table-limit{
		min-width: 900px;
		table{
			border-collapse: collapse;
			margin-top: 15px;
			width: 100%;
			td{
				border: 1px solid #ededed;
				padding: 15px 30px;
				&:nth-child(n+2){
					text-align: center;
				}
			}
			p{
				color: var(--c-font);
				font-size: 1.125rem /* 18/16 */;
				line-height: 1.2;
			}
		}
		.paper-head-tr td{
			text-align: center;
		}
		.paper-title{
			padding-left: 5px;
		}
		.total-price{
			span{
				display: inline-block;
				text-align: center;
				padding: 0 5px;
				width: 16.3%;
			}
		}
	}
	.receipt-detail{
		min-height: 160px;
		&.is-active{
			.item{
				grid-template-columns: auto repeat(4, 140px);
			}
		}
		.item{
            align-items: center;
			display: grid;
			grid-template-columns: auto repeat(2, 170px);
			+ .item{
				margin-top: -15px;
			}
			p{
				padding: 15px 30px;
				&:nth-child(n+2){
					text-align: center;
					padding: 15px;
				}
			}
		}
	}
	.sign-row{
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		padding: 30px;
		gap: 20px;
		.sign-item{
			align-items: center;
			display: flex;
			gap: 10px;
			.sign-title{
				color: var(--c-font);
				font-size: 1.125rem /* 18/16 */;
				flex-shrink: 0;
				line-height: 1;
			}
			.sign-img{
				max-width: 160px;
				img{
					display: block;
					width: 100%;
				}
			}
		}
	}
	.bottom-remark{
		border-top: 1px solid #e1e1e1;
		padding-top: 20px;
		p{
			color: var(--c-font);
			font-size: 1rem /* 16/16 */;
			line-height: 1.2;
			padding-left: 15px;
			position: relative;
			+ p{
				margin-top: 5px;
			}
			&:before{
				@include abs_pos($t:6px, $l:2px);
				@include size(5px);
				background: var(--c-primary);
				transform: rotate(45deg);
				content: '';
			}
		}
	}
}