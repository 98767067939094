/* -------------- pagination --------------*/
.page_box {
    @include flex($jfc: center);
    margin-top: 40px;

    .page_num {
        @include size(36px);
        @include flex($ai: center, $jfc: center);
        background: $c_white;
        border-radius: 50%;
        color: $c_main;
        font-size: 1rem
            /* 16/16 */
        ;
        margin-left: 8px;
        margin-right: 8px;

        &:hover {
            background: #eee;
        }

        &.active {
            border: 1px solid #ccc;
            background: $c_active;
            border-color: $c_active;
            color: $c_white;
        }

        i {
            font-size: 1rem
                /* 16/16 */
            ;
        }
    }

    .page-select{
        align-items: center;
        display: flex;
        gap: 0 10px;
        margin: 0 20px;
        select{
            color: var(--c-font);
            font-size: .875rem /* 14/16 */;
            min-width: 70px;
        }
        span{
            color: var(--c-font);
            font-size: .875rem /* 14/16 */;
            display: block;
        }
    }
}