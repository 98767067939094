/* -------------- layout RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .table-style{
        table{
            .th, .td{
                padding: 15px;
            }
        }
    }
    .receipt-wrapper {
        .logo{
            width: 300px;
        }
        .table-head {
            p{
                font-size: 1rem /* 16/16 */;
            }
            .receipt-title{
                font-size: 1.75rem /* 28/16 */;
            }
        }
        .table-limit {
            table {
                p{
                    font-size: 1rem /* 16/16 */;
                }
                td{
                    padding: 12px 15px;
                }
            }
            .total-price span{
                width: 15.5%;
            }
        }
        .receipt-detail .item p{
            padding: 12px;
        }
        .sign-row {
            padding: 20px 15px;
            .sign-item{
                .sign-title{
                    font-size: 1rem /* 16/16 */;
                }
                .sign-img{
                    width: 130px;
                }
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .slick-slider{
        .slick-arrow{
            @include size(28px);
            &.slick-prev{
                transform: translate(0, -50%);
            }
            &.slick-next{
                transform: translate(0, -50%);
            }
        }
    }
    .breadcrumb-row{
        padding: 5px 0;
        li{
            &:not(:last-child):after{
                font-size: .8125rem /* 13/16 */;
                padding: 0 3px;
            }
        }
        a, p{
            font-size: .8125rem /* 13/16 */;
        }
    }
}

@media (max-width: $w_tb_b){ 
    .table-style.mb-fm{
        display: block;
        .thead{
            display: none;
        }
        tr{
            border: 1px solid #dcdcdc;
            display: block;
            padding: 10px;
            &:not(:last-child){
                margin-bottom: 15px;
            }
        }
        .td{
            border: none;
            display: block;
            padding: 2px;
            min-height: 27px;
            text-align: left !important;
        }
        .has-mb-title{
            padding-left: 80px;
            position: relative;
            &:before{
                @include abs_pos($t:5px, $l:0);
                content: attr(data-title);
                color: var(--c-fontLight);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .slick-dotted .slick-dots{
        bottom: 6px;
        gap: 0 3px;
        li{
            transform: scale(.5);
            &.slick-active{
                transform: scale(.8);
            }
        }
    }
    .page-main-head {
        margin-bottom: 30px;
        .page-main-title{
            font-size: 1.25rem /* 20/16 */;
            padding: 8px 10px;
        }
    }
    .page-info-list{
        margin-top: -15px;
    }
    .page-relative-row{
        margin-top: 35px;
        padding-top: 0;
        .back-btn{
            margin-top: 20px;
        }
        .relative-item{
            border-bottom: 1px solid var(--c-border);
            position: static;
            padding: 13px 0;
            width: 100%;
            .des{
                margin-top: 5px;
            }
        }
        .next-item{
            text-align: left;
        }
    }
    .pop-sec{
        .pop-content{
            .btn-close{
                @include size(30px);
            }
        }
        .btn-row {
            margin-top: 15px;
            button.submit{
                font-size: 1rem /* 16/16 */;
                padding: 13px 10px;
                max-width: 180px;
            }
        }
    }
    .table-style{
        table{
            .th, .td{
                padding: 10px;
            }
        }
    }
    .receipt-wrapper {
        .receipt-detail{
            min-height: 100px;
        }
        .logo{
            width: 240px;
        }
        .bottom-remark p{
            font-size: .9375rem /* 15/16 */;
        }
    }
}

// @media (max-width: $w_mo){ }