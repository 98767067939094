/* -------------- about RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-about{
        .director-head{
            margin-bottom: 25px;
            .filter-select{
                position: static;
                transform: translateY(0);
                margin: 0 auto 15px;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }