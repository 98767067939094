/* -------------- header --------------*/
header{
    position: sticky;
    top: 0;
    z-index: 2;
}

.header{
    @extend %clearStyle;
    box-shadow: 0 1px 3px #ddd;
    background: var(--c-white);
    &-top{
        background: url(../images/bg-header-top.png) no-repeat;
        background-position: bottom right;
        padding: 7px 0 15px;
    }
    .sns{
        display: flex;
        justify-content: flex-end;
        gap: 0 12px;
        .sns-link{
            @include size(24px);
            @include flex($ai:center, $jfc:center);
            border-radius: 50%;
            border: 1px solid var(--c-white);
            &:hover{
                background: var(--c-white);
                svg{
                    color: var(--c-success);
                }
            }
            svg{
                color: var(--c-white);
                font-size: .75rem /* 12/16 */;
                line-height: 1;
            }
        }
    }
    &-main-row{
        @include flex($ai:center, $jfc:space-between);
        padding: 15px 0;
    }
    .logo{
        width: 280px;
        img{
            display: block;
            width: 100%;
        }
    }
    .main-menu{
        @include flex($ai:center);
        gap: 10px 50px;
        > li{
            position: relative;
            > a{
                color: var(--c-primary);
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                &:hover{
                    color: var(--c-success);
                }
            }
        }
        .member-link{
            align-items: center;
            border-radius: 50px;
            background: var(--c-primary);
            color: var(--c-white);
            display: flex;
            justify-content: center;
            gap: 0 10px;
            padding: 12px 15px;
            width: 170px;
            &:hover{
                background: var(--c-success);
                color: var(--c-white);
            }
            img{
                display: block;
                width: 18px;
            }
        }
        .member-submenu-row{
            @include abs_pos($r:-40px, $b:0);
            display: none;
            transform: translateY(100%);
            padding-top: 20px;
            width: 540px;
        }
        .member-submenu{
            background: var(--c-white);
            display: grid;
            grid-template-columns: 40% auto;
            gap: 0 40px;
            padding: 35px 40px;
            .dec-img{
                display: block;
                width: 100%;
            }
            .member-submenu-list{
                display: grid;
                grid-template-columns: 30% auto;
                gap: 10px 20px;
                a{
                    color: var(--c-font);
                    display: block;
                    font-size: 1rem /* 16/16 */;
                    line-height: 1.2;
                    + a{
                        margin-top: 10px;
                    }
                    &:hover{
                        color: var(--c-success);
                    }
                }
            }
        }
    }
    nav{
        display: flex;
    }
}

.mobile-toggle {
    @include size(28px, 30px);
    background: none;
    cursor: pointer;
    display: none;
    transform: translateY(13px);
    padding: 0;
    z-index: 4;

    &.open {

        span {
            background: none;

            &:before {
                transform: rotate(45deg) translate(6px, 5px);
            }

            &:after {
                transform: rotate(-45deg) translate(7px, -8px);
            }
        }
    }

    span {
        display: block;
        position: relative;

        &:before,
        &:after {
            @include abs_pos($t: -9px, $l: 0);
            content: "";
        }

        &:after {
            top: 9px;
        }
    }

    span,
    span:before,
    span:after {
        @include size(100%, 4px);
        backface-visibility: hidden;
        border-radius: 2px;
        background: $c_font;
        // transition: all .4s ease;
    }
}