/* -------------- footer --------------*/
footer{
    @extend %clearStyle;
    background: var(--c-primary);
    padding: 45px 0;
    .frame{
        align-items: center;
        display: grid;
        grid-template-columns: auto 430px;
        gap: 0 30px;
    }
    .contact-info-row{
        @include flex;
        gap: 10px 15px;
        + .contact-info-row{
            margin-top: 10px;
        }
    }
    .contact-info{
        display: flex;
        color: var(--c-white);
        font-size: 1.0625rem /* 17/16 */;
        line-height: 1.4;
        span{
            flex-shrink: 0;
        }
        a{
            color: var(--c-white);
            &:hover{
                text-decoration: underline;
            }
        }
        .fax{
            cursor: auto;
            &:hover{
                text-decoration: none;
            }
        }
        .mail{
            color: var(--c-success);
        }
    }
    .rigths-sec{
        text-align: right;
        p, a{
            font-size: .9375rem /* 15/16 */;
            line-height: 1.4;
        }
        .rights{
            color: var(--c-white);
            margin-bottom: 10px;
        }
        .designed, b{
            color: #b5b5b5;
        }
    }
}

.right-active{
    background: var(--c-success);
    padding: 20px 10px;
    position: fixed;
    right: 0;
    bottom: 20dvh;
    width: 50px;
    z-index: 1;
    .search-btn{
        border-bottom: 1px solid var(--c-white);
        color: var(--c-white);
        display: block;
        font-size: .9375rem /* 15/16 */;
        line-height: 1.2;
        padding-bottom: 15px;
        margin-bottom: 15px;
        text-align: center;
        &:hover{
            color: var(--c-primary);
        }
        p{
            line-height: 1.2;
            margin-top: 5px;
        }
    }
    .member-link{
        border-bottom: 1px solid var(--c-white);
        display: block;
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:hover p{
            color: var(--c-primary);
        }
        img{
            display: block;
            width: 100%;
        }
        p{
            color: var(--c-white);
            font-size: .9375rem /* 15/16 */;
            line-height: 1.2;
            margin-top: 10px;
        }
    }
    .top-btn{
        color: var(--c-white);
        display: block;
        &:hover span{
            color: var(--c-primary);
        }
        svg{
            font-size: .9375rem /* 15/16 */;
            display: block;
            line-height: 1;
            margin-left: auto;
            margin-right: auto;
        }
        span{
            display: block;
            font-size: .9375rem /* 15/16 */;
            text-align: center;
        }
    }
}