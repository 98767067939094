/* -------------- link --------------*/
.page-link{
    .link-grid{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 30px;
    }
    .out-link-item{
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 10px;
    }
}

