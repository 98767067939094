/* -------------- header RWD --------------*/
@media (max-width: $w_pc_b){ 
    .header{
        .main-menu{
            gap: 30px;
            .member-link{
                padding: 10px 15px;
                width: 140px;
            }
            .member-submenu-row{
                width: 500px;
            }
            .member-submenu{
                gap: 0 30px;
                padding: 25px 30px;
            }
        }
    }
}

// @media (max-width: $w_pc){ }

@media (max-width: $w_pc_m){ 
    .header{
        &-top{
            background-position: center right;
            padding-bottom: 10px;
        }
        .logo{
            width: 240px;
        }
        &-main-row{
            padding: 10px 0;
        }
        nav{
            background: url(../images/repeat-1.jpg);
            position: fixed;
            display: none;
            top: 0;
            right: 0;
            padding: 20px;
            padding-top: 50px;
            overflow-y: auto;
            width: min(80vw, 300px);
            height: 100dvh;
        }
        .main-menu{
            flex-direction: column;
            gap: 10px;
            > li{
                border-top: 1px dashed #bcc8d5;
                text-align: center;
                padding-top: 10px;
                width: 100%;
            }
            .member-link{
                width: 100%;
            }
            .member-submenu-row{
                position: static;
                padding-top: 15px;
                transform: translateY(0);
                width: 100%;
            }
            .member-submenu{
                grid-template-columns: 1fr;
                padding: 15px;
                .dec-img{
                    display: none;
                }
                .member-submenu-list{
                    grid-template-columns: 1fr;
                    gap: 10px;
                    a{
                        text-align: center;
                        + a{
                            border-top: 1px dashed var(--c-border);
                            padding-top: 7px;
                            margin-top: 7px;
                        }
                    }
                }
            }
        }

    }
    .mobile-toggle{
        display: block;
        &.open {
            margin-top: -100px;
        }
    }
}

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .header{
        &-top{
            background-position: bottom center;
            padding-bottom: 7px;
        }
        .sns{
            gap: 0 10px;
            .sns-link{
                @include size(20px);
            }
        }
    }
    .mobile-toggle{
        &.open {
            margin-top: -80px;
        }
    }
}

// @media (max-width: $w_mo){ }