/* -------------- footer RWD --------------*/
@media (max-width: $w_pc_b){ 
    .right-active{
        width: 40px;
        padding: 15px 5px;
        .top-btn span{
            font-size: .875rem /* 14/16 */;
        }
    }
}

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    footer {
        padding: 35px 0;
        .frame{
            grid-template-columns: auto 400px;
        }
        .contact-info{
            font-size: 1rem /* 16/16 */;
        }
    }
}

@media (max-width: $w_tb){ 
    footer {
        padding: 30px 0;
        .frame{
            grid-template-columns: 1fr;
        }
        .contact-info-row{
            gap: 7px 15px;
            + .contact-info-row{
                margin-top: 7px;
            }
        }
        .contact-info{
            font-size: .9375rem /* 15/16 */;
        }
        .rigths-sec{
            border-top: 1px solid rgba(white, .3);
            padding-top: 15px;
            margin-top: 15px;
            text-align: left;
            p, a{
                font-size: .875rem /* 14/16 */;
            }
            .rights{
                margin-bottom: 5px;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .right-active{
        bottom: 10dvh;
        width: 35px;
        padding: 10px 3px;
        .search-btn {
            font-size: .75rem /* 12/16 */;
            margin-bottom: 10px;
            padding-bottom: 10px;
        }
        .member-link {
            margin-bottom: 10px;
            padding-bottom: 10px;
            p{
                font-size: .75rem /* 12/16 */;
            }
        }
        .top-btn span{
            font-size: .75rem /* 12/16 */;
        }
    }
}

// @media (max-width: $w_mo){ }