/* -------------- news --------------*/
.page-news{
    .news-grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
    }
    .news-item{
        @extend %clearStyle;
        .img-box{
            display: block;
        }
        .content{
            background: url(../images/repeat-2.jpg);
            padding: 10px 20px;
        }
        .top-des{
            @include flex;
            border-bottom: 1px solid #e0e0e0;
            padding: 10px 5px;
            p{
                color: var(--c-fontLight);
                font-size: .9375rem /* 15/16 */;
                font-weight: lighter;
                line-height: 1.2;
                &:not(:last-child):after{
                    content: '/';
                    color: var(--c-fontLight);
                    font-size: .9375rem /* 15/16 */;
                    font-weight: lighter;
                    padding: 0 3px;
                }
            }
        }
        .main-content{
            padding: 15px 5px 10px;
            .news-title{
                color: var(--c-font);
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                line-height: 1.3;
                &:hover{
                    color: var(--c-success);
                }
            }
            .des{
                @include lineLimit(1);
                color: var(--c-fontLight);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                margin-top: 10px;
                margin-bottom: 15px;
            }
            .more{
                color: var(--c-primary);
                font-size: 1rem /* 16/16 */;
                &:hover{
                    color: var(--c-success);
                }
                svg{
                    margin-left: 5px;
                }
            }
        }
    }
}

