/* -------------- about --------------*/
.page-about{
    .director-head{
        position: relative;
        margin-bottom: 30px;
        .head{
            color: var(--c-primary);
            font-size: 1.25rem /* 20/16 */;
            font-weight: bold;
            text-align: center;
            margin: 0;
        }
        .filter-select{
            @include abs_pos($t:50%, $r:0);
            transform: translateY(-50%);
            margin-bottom: 0;
        }
    }
    .director-table{
        .th{
            width: max(120px, 10vw);
        }
    }
}

