/* -------------- form RWD --------------*/
@media (max-width: $w_pc_b){ 
    .form-style{
        .field-grid{
            padding: 30px 0;
            gap: 20px 25px;
        }
    }
}

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .form-style{
        .field-grid{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px 20px;
        }
    }
}

@media (max-width: $w_tb){ 
    .form-style{
        .field{
            .field-text{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .form-style{
        .form-attention p{
            font-size: 1rem /* 16/16 */;
        }
        .field-head-row{
            padding: 15px 20px;
        }
        .field-grid{
            grid-template-columns: 1fr;
            padding: 20px 0;
            .field.third-two{
                grid-column: initial;
            }
        }
        .field .field-label{
            margin-bottom: 5px;
        }
        .address-field .field-content{
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
            input{
                grid-column: 1 / -1;
            }
        }
    }
    .keyword-form{
        max-width: 100%;
    }
}

// @media (max-width: $w_mo){ }