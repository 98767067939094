/* -------------- member RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-member{
        .login-sec-row{
            gap: 30px;
        }
        .register-flow {
            margin-bottom: 40px;
            .flow {
                gap: 5px;
                padding: 0 10px;
                .no{
                    @include size(33px);
                    p{
                        font-size: 1.125rem /* 18/16 */;
                    }
                }
                .flow-title{
                    font-size: 1rem /* 16/16 */;
                }
            }
        }
        .wsava-grid{
            gap: 30px;
        }
        .board-detail {
            .post-row{
                grid-template-columns: 170px auto;
            }
            .post-sec{
                padding: 40px;
            }
            .poster .info{
                font-size: .9375rem /* 15/16 */;
                span{
                    display: block;
                    font-weight: bold;
                    margin-bottom: 3px;
                }
            }
            .content-row > div{
                padding: 15px;
            }
        }
        .calendar-item {
            .content{
                grid-template-columns: auto 100px;
                padding: 20px;
            }
            .more-link{
                padding: 13px 10px;
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-member{
        .reg-inquiry-table{
            a{
                font-weight: bold;
            }
        }
        .board-list-table .td.has-mb-title{
            padding-left: 145px;
        }
        .calendar-item {
            .content{
                grid-template-columns: 1fr;
            }
            .des{
                -webkit-line-clamp: 2;
                line-height: 1.4;
            }
        }
    }
}

@media (max-width: $w_tb){ 
    .page-member{
        .login-sec-row{
            flex-direction: column;
            .login-form{
                padding: 0;
            }
            .field + .field{
                margin-top: 15px;
            }
            .sep{
                justify-content: center;
                &:before{
                    @include size(100%, 1px);
                    left: 0;
                    top: 50%;
                }
                p{
                    padding: 0 10px;
                }
            }
            .sns-login{
                padding: 0;
                .intro-text{
                    font-size: 1rem /* 16/16 */;
                    margin-bottom: 20px;
                }
            }
        }
        .wsava-grid{
            grid-template-columns: repeat(2, 1fr);
            gap: 25px 20px;
        }
        .board-detail {
            .post-row{
                grid-template-columns: 1fr;
            }
            .post-head {
                p{
                    font-size: 1rem /* 16/16 */;
                    padding: 10px;
                    &:nth-child(2){
                        display: none;
                    }
                }
            }
            .post-sec{
                padding: 25px 10px;
            }
            .poster{
                border: none;
                .name{
                    margin-bottom: 10px;
                    text-align: left;
                }
                .info {
                    + .info{
                        margin-top: 3px;
                    }
                    span{
                        display: inline-block;
                    }
                }
            }
            .content-row{
                .poster{
                    border-bottom: 1px solid var(--c-border);
                    background: #f9f9f9;
                }
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-member{
        .register-flow {
            margin-bottom: 20px;
            gap: 5px;
            .flow {
                flex-direction: column;
                padding: 0 5px;
                text-align: center;
            }
        }
        .term-limit{
            .term-content{
                height: 400px;
            }
        }
        .term-btn-row{
            gap: 0 20px;
            a, button{
                font-size: 1rem /* 16/16 */;
                max-width: 150px;
            }
        }
        .register-final-sec{
            padding-top: 20px;
            .des{
                font-size: .9375rem /* 15/16 */;
            }
        }
        .wsava-top-row{
            align-items: flex-start;
            flex-direction: column;
            .filter-select{
                margin-left: 0;
            }
        }
        .board-top-row{
            align-items: flex-start;
            flex-direction: column;
        }
        .calendar-top-row{
            flex-direction: column;
        }
        .calendar-item{
            grid-template-columns: 1fr;
            .img-box{
                max-width: 400px;
            }
        }
        .event-list{
            margin-bottom: 30px;
        }
        .event-item{
            grid-template-columns: 80px auto;
            p{
                font-size: 1rem /* 16/16 */;
            }
        }
    }
}

@media (max-width: $w_mo){ 
    .page-member{
        .wsava-grid{
            grid-template-columns: 1fr;
            .wsava-item .content{
                padding: 20px 10px;
            }
        }
    }
}