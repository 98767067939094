/* -------------- news RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-news{
        .news-grid{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .page-news{
        .news-grid{
            gap: 25px 20px;
        }
    }
}

@media (max-width: $w_tb_m){ 
    .page-news{
        .news-grid{
            grid-template-columns: repeat(2, 1fr);
        }
        .news-item {
            .content{
                padding: 10px 5px;
            }
            .main-content .des{
                margin-top: 7px;
                margin-bottom: 10px;
            }
        }
    }
}

// @media (max-width: $w_mo){ }