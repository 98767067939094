/* -------------- link RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
    .page-link{
        .link-grid{
            grid-template-columns: repeat(4, 1fr);
            gap: 20px;
        }
    }
}

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-link{
        .link-grid{
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m){ 
    .page-link{
        .link-grid{
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
    }
}

// @media (max-width: $w_mo){ }