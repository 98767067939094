/* -------------- medical --------------*/
.page-medical{
    .medical-grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px 45px;
    }
    .medical-item{
        @extend %clearStyle;
        background: url(../images/repeat-2.jpg);
        display: grid;
        grid-template-columns: 40% auto;
        .content{
            padding: 25px 30px;
        }
        .top-des{
            .category{
                color: var(--c-primary);
                font-size: .9375rem /* 15/16 */;
                svg{
                    margin-right: 5px;
                }
            }
        }
        .main-content{
            margin-top: 15px;
        }
        .medical-title{
            @include lineLimit(2);
            color: var(--c-font);
            font-size: 1.1875rem /* 19/16 */;
            line-height: 1.3;
            &:hover{
                color: var(--c-success);
            }
        }
        .des{
            @include lineLimit(1);
            color: var(--c-fontLight);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            margin-top: 10px;
        }
        .more{
            color: var(--c-primary);
            display: inline-block;
            font-size: 1rem /* 16/16 */;
            margin-top: 15px;
            &:hover{
                color: var(--c-success);
            }
            svg{
                margin-left: 5px;
            }
        }
    }
}

