/* -------------- client --------------*/
.page-client{
    .client-table{
        .map-link{
            text-align: center;
            a{
                color: var(--c-success);
                display: block;
                font-size: 1.125rem /* 18/16 */;
                line-height: 1;
                &:hover{
                    color: var(--c-primary);
                }
            }
        }
    }
}

