/* -------------- client RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

@media (max-width: $w_tb_b){ 
    .page-client{
        .client-table{
            .client-title{
                color: var(--c-primary);
                font-weight: bold;
                font-size: 1.0625rem /* 17/16 */;
            }
            .map-link{
                text-align: left;
            }
        }
    }
}

// @media (max-width: $w_tb){ }

// @media (max-width: $w_tb_m){ }

// @media (max-width: $w_mo){ }