/* -------------- container RWD --------------*/
// @media (max-width: $w_pc_b){ }

@media (max-width: $w_pc){ 
	.page-frame{
		padding: 45px 0 70px;
		.frame{
			gap: 40px;
		}
		aside{
			width: 220px;
		}
		.aside-head{
			grid-template-columns: 90px auto;
			.ch{
				font-size: 1.5rem /* 24/16 */;
			}
			.en{
				font-size: .8125rem /* 13/16 */;
				margin-top: 7px;
			}
		}
		.aside-menu li{
			padding: 15px 20px 15px 25px;
		}
	}
}

@media (max-width: $w_pc_m){ 
	.page-frame{
		padding: 25px 0 50px;
		.frame{
			flex-wrap: wrap;
			gap: 20px;
		}
		aside{
			width: 100%;
		}
		.aside-menu{
			@include flex;
			gap: 2px;
			li{
				padding: 8px 10px;
			}
			a{
				position: relative;
				padding-left: 15px;
				&:hover{
					text-decoration: underline;
				}
				&:before{
					@include abs_pos($t:3px, $l:0);
					content: '\f0da';
					color: var(--c-success);
					font-size: 1rem /* 16/16 */;
					font-family: 'Font Awesome 6 Free';
					font-weight: bold;
					line-height: 1;
				}
			}
		}
	}
}

// @media (max-width: $w_tb_b){ }

@media (max-width: $w_tb){ 
    .frame{
		padding-left: 20px;
		padding-right: 20px;
	}
}

@media (max-width: $w_tb_m){ 
	.page-frame .aside-menu a{
		font-size: 1rem /* 16/16 */;
	}
}

// @media (max-width: $w_mo){ }