/* -------------- form --------------*/
button, input[type="reset"], input[type="submit"]{
	cursor: pointer;
}

input[type="reset"], input[type="submit"], input[type="text"], input[type="email"], input[type="tel"], textarea, select, input[type="date"], input[type="time"], input[type="number"], input[type="password"], input[type="url"], button{
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	font-family: 'Open Sans', Arial, 'LiHei Pro', 'fix Microsoft JhengHei', 'Microsoft JhengHei','新細明體', sans-serif;
	box-shadow: none;
	border: none;
	outline: none;
}

select::-ms-expand{
	display: none;
}

.select-style{
	background: $c_white;
    position: relative;
    &:before{
        @include abs_pos($t: 50%, $r: 10px);
        content: '\f0dd';
        display: block;
        font-family: 'Font Awesome 6 Free';
        font-weight: bold;
        color: $c_font;
        transform: translateY(-80%);
    }
    select{
        border: 1px solid var(--c-border);
        border-radius: 3px;
        color: var(--c-font);
        font-size: 1rem /* 16/16 */;
        line-height: 1.2;
        background: transparent;
        padding: 12px 10px;
        padding-right: 25px;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}

.form-style{
    --c-border: #d4d4d4;
    @extend %clearStyle;
    .form-attention{
        margin-bottom: 15px;
        p{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.2;
        } 
        .must-star{
            color: var(--c-success);
        }
    }
    .field-head-row{
        background: url(../images/repeat-3.jpg);
        display: flex;
        padding: 20px 25px;
        gap: 0 10px;
        .ic{
            width: 20px;
            img{
                display: block;
                width: 100%;
            }
        }
        .head-text{
            color: var(--c-primary);
            font-size: 1.125rem /* 18/16 */;
            font-weight: bold;
            line-height: 1;
        }
    }
    .field-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 40px 0;
        gap: 25px 35px;
        &:first-child{
            padding-top: 0;
        }
        .field.third-two{
            grid-column: span 2;
        }
        .field.max-field{
            grid-column: 1 / -1;
        }
    }
    .field{
        .form-attention{
            margin-bottom: 10px;
            .must-star{
                color: var(--c-danger);
            }
        }
        .field-label{
            margin-bottom: 8px;
        }
        .field-text{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.2;
            &.must{
                position: relative;
                &:after{
                    color: var(--c-danger);
                    content: '*';
                    font-size: 1rem /* 16/16 */;
                    padding-left: 3px;
                }
            }
            span{
                color: var(--c-primary);
                font-size: .875rem /* 14/16 */;
            }
        }
        .field-content{
            input[type="text"], input[type="email"], input[type="tel"], input[type="date"], input[type="password"], textarea{
                border: 1px solid var(--c-border);
                border-radius: 3px;
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
                padding: 12px 13px;
                width: 100%;
            }
        }
        textarea{
            resize: none;
            height: 500px;
        }
        .fg-row{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }
        .fg-pw{
            color: var(--c-font);
            font-size: .875rem /* 14/16 */;
            line-height: 1.2;
            &:hover{
                color: var(--c-success);
            }
        }
        
        .remark{
            color: var(--c-danger);
            font-size: .875rem /* 14/16 */;
            padding-top: 3px;
        }
    }
    .address-field .field-content{
        display: grid;
        grid-template-columns: 23% 23% auto;
        gap: 15px;
    }
    .checked-list{
        @include flex($ai:center);
        gap: 10px 15px;
    }
    .checked-item{
        display: flex;
        input{
            @include size(16px);
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border-radius: 3px;
            border: 1px solid var(--c-border);
            flex-shrink: 0;
            margin: 0;
            margin-right: 5px;
            margin-top: 1px;
            position: relative;
            &:checked{
                background: var(--c-success);
                border-color: var(--c-success);
                &:after{
                    @include abs_pos($t:1px, $l:2px);
                    content: '\f00c';
                    color: var(--c-white);
                    font-family: 'Font Awesome 6 Free';
                    font-weight: bold;
                    font-size: .8125rem /* 13/16 */;
                }
            }
        }
        span{
            color: var(--c-font);
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
        }
        a{
            color: var(--c-primary);
            display: inline-block;
            text-decoration: underline;
            text-underline-offset: 3px;
            &:hover{
                color: var(--c-success);
            }
        }
    }
    .radio-item{
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        input[type="radio"]{
            @include size(16px);
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none;
            appearance: none;
            border-radius: 50%;
            border: 1px solid var(--c-border);
            flex-shrink: 0;
            margin: 0;
            margin-right: 5px;
            margin-top: 1px;
            position: relative;
            &:checked{
                border-color: var(--c-success);
                &:after{
                    @include abs_pos($t:2px, $l:2px);
                    content: '';
                    @include size(10px);
                    background: var(--c-success);
                    border-radius: 50%;
                }
            }
        }
        span{
            color: var(--c-font);
            display: block;
            padding-right: 5px;
            font-size: 1rem /* 16/16 */;
            line-height: 1.2;
            word-break: keep-all;
        }
        a{
            color: var(--c-primary);
            display: inline-block;
            text-decoration: underline;
            text-underline-offset: 3px;
            &:hover{
                color: var(--c-success);
            }
        }
    }
    .btn-row{
        display: flex;
        gap: 30px;
        a, button{
            border-radius: 3px;
            color: var(--c-white);
            display: block;
            font-size: 1.125rem /* 18/16 */;
            line-height: 1;
            padding: 15px 10px;
            text-align: center;
            flex: 1;
        }
        a{
            border: 1px solid #707070;
            background: #707070;
            &:hover{
                background: var(--c-white);
                color: #707070;
            }
        }
        .submit{
            border: 1px solid var(--c-primary);
            background: var(--c-primary);
            &:hover{
                background: var(--c-white);
                color: var(--c-primary);
            }
        }
    }
}

.filter-select{
    @include maw(250px);
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    margin-bottom: 25px;
    margin-left: auto;
    &:before{
        z-index: 1;
    }
    &:after{
        @include abs_pos($t:0, $r:0);
        @include size(30px, 100%);
        background: url(../images/repeat-3.jpg);
        content: "";
    }
    select{
        border-color: #dcdcdc;
        padding-right: 30px;
    }
}

.filter-date-range{
    display: grid;
    grid-template-columns: repeat(2, 1fr) 40px;
    gap: 5px;
    max-width: 340px;
    input{
        border: 1px solid var(--c-border);
        border-radius: 3px;
        background: var(--c-white);
        color: var(--c-font);
        font-size: 1rem /* 16/16 */;
        line-height: 1.2;
        padding: 10px 7px;
        min-height: 43px;
        width: 100%;
    }
    input::-webkit-date-and-time-value{
        text-align: left;
        background: transparent;
    }
    button{
        background: var(--c-primary);
        border-radius: 5px;
        font-size: 1rem /* 16/16 */;
        padding: 0;
        color: var(--c-white);
        &:hover{
            background: var(--c-success);
        }
    }
}

.keyword-form{
    @include maw(330px);
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    display: grid;
    grid-template-columns: auto 60px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-left: auto;
    input{
        font-size: 1rem /* 16/16 */;
        padding: 10px;
    }
    button{
        background: url(../images/repeat-2.jpg);
        padding: 10px 5px;
        width: 60px;
        &:hover{
            background: var(--c-border);
        }
        img{
            display: block;
            margin: auto;
            width: 20px;
        }
    }
}

.price-fm{
    font-size: 1.0625rem /* 17/16 */;
    &:before{
        content: '$';
        display: inline-block;
        padding-right: 3px;
    }
}

.fileItem{
    display: flex;
    gap: 10px;
    + .fileItem{
        margin-top: 10px;
    }
    .imgBox{
        border: 1px solid var(--c-border);
        background: #fafafa;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        img{
            display: block;
            max-width: 100%;
            max-height: 100%;
        }
    }
    input[type="file"]{
        display: none;
    }
    .selectBtn{
        background: var(--c-success);
        border-radius: 3px;
        color: var(--c-white);
        cursor: pointer;
        display: inline-block;
        font-size: 1rem /* 16/16 */;
        line-height: 1;
        padding: 10px 15px;
        &:hover{
            background: var(--c-primary);
        }
    }
    .fileName{
        color: var(--c-fontLight);
        display: block;
        font-size: .9375rem /* 15/16 */;
        line-height: 1.2;
        margin-top: 5px;
    }
}