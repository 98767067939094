/* -------------- index --------------*/
.index{
    &-banner{
        position: relative;
        padding-bottom: 50px;
        .news-slider{
            @include abs_pos($b:0, $l:calc((100vw - 1480px) / 2));
            width: 460px;
        }
        .news-item{
            padding: 0 10px;
            .img-box{
                display: block;
                border-radius: 20px;
                overflow: hidden;
                position: relative;
                &:hover .cover{
                    opacity: 1;
                }
                .cover{
                    @include abs_pos($b:0, $l:0);
                    background: rgba(#345277, .85);
                    padding: 20px;
                    opacity: 0;
                    transition: all .4s ease;
                    width: 100%;
                    .title{
                        @include lineLimit(2);
                        color: var(--c-white);
                        font-size: 1.0625rem /* 17/16 */;
                        font-weight: bold;
                        line-height: 1.4;
                        margin: 0;
                    }
                }
            }
        }
    }
    &-events{
        @extend %clearStyle;
        padding: 100px 0;
        .frame{
            align-self: flex-start;
            display: grid;
            grid-template-columns: 63% auto;
            gap: 40px 90px;
        }
        .event-head{
            border-bottom: 2px solid var(--c-border);
            .ch{
                border-bottom: 4px solid var(--c-primary);
                color: var(--c-font);
                display: inline-block;
                font-size: 1.875rem /* 30/16 */;
                line-height: 1;
                letter-spacing: 1px;
                padding: 20px 25px;
                padding-top: 0;
                margin-bottom: -3px;
            }
        }
        .event-list{
            padding: 40px 20px;
        }
        .event{
            padding-left: 15px;
            position: relative;
            &:before{
                @include abs_pos($t:0, $l:0);
                content: '-';
                font-size: 1rem /* 16/16 */;
                color: var(--c-font);
            }
            +.event{
                margin-top: 25px;
            }
            .event-title{
                align-items: baseline;
                display: flex;
                gap: 0 10px;
                .event-name{
                    color: var(--c-font);
                    display: inline-block;
                    font-size: 1.3125rem /* 21/16 */;
                    line-height: 1.3;
                    &:hover{
                        text-decoration: underline;
                    }
                }
                .btn-booked{
                    color: var(--c-success);
                    flex-shrink: 0;
                    font-size: 1rem /* 16/16 */;
                    line-height: 1.2;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
            .event-des{
                @include lineLimit(1);
                color: var(--c-fontLight);
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                margin-top: 8px;
            }
        }
        .btn-more{
            @include maw(250px);
            background: var(--c-primary);
            border-radius: 3px;
            color: var(--c-white);
            display: block;
            text-align: center;
            line-height: 1;
            padding: 17px 10px;
            margin-left: auto;
            margin-right: auto;
            &:hover{
                background: var(--c-success);
            }
        }
        .calendar-head{
            display: grid;
            grid-template-columns: 25px auto 25px;
            margin-bottom: 20px;
            .calendar-aw{
                color: var(--c-primary);
                font-size: 1.375rem /* 22/16 */;
                line-height: 1.2;
                &:hover{
                    color: var(--c-success);
                }
            }
            .next-month{
                text-align: right;
            }
            .calendar-current{
                display: flex;
                color: var(--c-primary);
                font-size: 2.125rem /* 34/16 */;
                font-weight: bold;
                justify-content: center;
                gap: 0 15px;
                line-height: 1;
            }
        }
        .calendar-week{
            margin-bottom: 7px;
        }
        .calendar-row{
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 15px;
            &.calendar-week .cell p{
                font-weight: normal;
            }
            .cell{
                @include fiximg(100%, 100%);
                position: relative;
                &.is-empty{
                    opacity: 0;
                }
                &.is-weekwend p{
                    color: var(--c-primary);
                }
                &.is-active{
                    background: var(--c-success);
                    display: block;
                    &:hover{
                        background: var(--c-fontLight);
                    }
                    p{
                        color: var(--c-white);
                    }
                }
                p{
                    @extend %center;
                    color: var(--c-font);
                    font-size: 1.375rem /* 22/16 */;
                    font-weight: lighter;
                    line-height: 1;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
    &-medical{
        @extend %clearStyle;
        background: url(../images/repeat-3.jpg);
        .frame{
            display: flex;
            position: relative;
            padding-top: 120px;
            padding-bottom: 120px;
            gap: 20px 0;
            &:before{
                @include abs_pos($t:0, $l:0);
                @include size(250px, 336px);
                background: url(../images/index-dog.png) no-repeat;
                background-size: contain;
                content: '';
                transform: translateY(-61%);
                display: none;
            }
        }
        .head-sec{
            flex-shrink: 0;
            width: 170px;
            .head{
                .en{
                    color: var(--c-primary);
                    font-size: 2.125rem /* 34/16 */;
                    line-height: 1;
                }
                .ch{
                    color: var(--c-font);
                    font-size: 1.875rem /* 30/16 */;
                    line-height: 1;
                    margin-top: 30px;
                }
            }
            .more{
                align-items: flex-end;
                border-bottom: 1px solid var(--c-font);
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                padding: 0 5px 10px;
                &:hover{
                    padding-right: 0;
                    p{
                        color: var(--c-success);
                    }
                }
                p{
                    color: var(--c-font);
                    font-size: 1.0625rem /* 17/16 */;
                    line-height: 1.2;
                }
                img{
                    display: block;
                    margin-bottom: 5px;
                    width: 35px;
                }
            }
            .slider-aw-row{
                display: flex;
                justify-content: flex-end;
                gap: 0 10px;
                margin-top: 10px;
                .slick-arrow{
                    @include size(30px);
                    background: var(--c-primary);
                    border-radius: 50%;
                    color: var(--c-white);
                    cursor: pointer;
                    &:hover{
                        background: var(--c-success);
                    }
                }
            }
        }
        .medical-row{
            padding-left: 90px;
            width: calc(100% - 170px);
        }
        .medical{
            padding: 0 10px;
            .box{
                align-items: center;
                display: grid;
                grid-template-columns: 40% auto;
            }
            .img-box{
                display: block;
            }
            .content{
                padding: 20px;
            }
            .category{
                align-items: center;
                color: var(--c-primary);
                display: flex;
                font-size: .9375rem /* 15/16 */;
                gap: 5px;
                margin-bottom: 10px;
                svg{
                    text-align: center;
                    width: 20px;
                }
                p{
                    width: 100%;
                }
            }
            .medical-title{
                display: inline-block;
                color: var(--c-font);
                font-size: 1.25rem /* 20/16 */;
                line-height: 1.3;
                &:hover{
                    color: var(--c-success);
                }
            }
            .des{
                @include lineLimit(3);
                color: var(--c-fontLight);
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1.4;
                margin-top: 15px;
            }
            .more{
                color: var(--c-primary);
                display: inline-block;
                font-size: 1rem /* 16/16 */;
                line-height: 1;
                margin-top: 20px;
                &:hover{
                    color: var(--c-success);
                }
                svg{
                    padding-left: 5px;
                }
            }
        }
    }
    &-links{
        @extend %clearStyle;
        display: flex;
        .out-link-sec{
            background: url(../images/bg-index-link.png) no-repeat;
            background-position: center center;
            background-size: cover;
            padding: 150px 80px;
            width: 57vw;
        }
        .out-link-item{
            padding: 0 10px;
        }
        .clinic-area-sec{
            @include maw(570px);
            background: url(../images/index-client-bg.jpg) no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
        }
        .clinic-form{
            @include abs_pos($l:0, $b:0);
            display: flex;
            padding: 60px 50px;
            width: 100%;
            .clinic-head{
                background: var(--c-primary);
                color: var(--c-white);
                flex-shrink: 0;
                font-size: 1.25rem /* 20/16 */;
                line-height: 1;
                padding: 15px 25px;
            }
            .select-style{
                border: 1px solid var(--c-border);
                width: 100%;
                select{
                    color: var(--c-font);
                    font-size: 1.1875rem /* 19/16 */;
                }
            }
        }
    }
    &-pacs{
        display: grid;
        gap: 10px;
        grid-template-columns: auto 24.7% 24.7%;
        a{
            display: block;
            img{
                display: block;
                width: 100%;
            }
        }
    }
}