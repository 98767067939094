/* -------------- pagination RWD --------------*/
// @media (max-width: $w_pc_b){ }

// @media (max-width: $w_pc){ }

// @media (max-width: $w_pc_m){ }

// @media (max-width: $w_tb_b){ }

// @media (max-width: $w_tb){ }

@media (max-width: $w_tb_m) {
    .page_box {
        margin-top: 30px;
        .page_num {
            @include size(30px);
            margin-left: 3px;
            margin-right: 3px;
        }
        .page-select{
            margin: 0;
            margin-top: 20px;
            justify-content: center;
            width: 100%;
        }
    }
}

// @media (max-width: $w_mo){ }