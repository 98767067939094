/* -------------- member --------------*/
.page-member{
    .login-sec-row{
        @extend %clearStyle;
        display: flex;
        gap: 40px 60px;
        .login-form{
            padding: 30px 0;
            flex: 1;
        }
        .field + .field{
            margin-top: 25px;
        }
        .sep{
            align-items: center;
            position: relative;
            display: flex;
            &:before{
                @include abs_pos($t:0, $l:50%);
                @include size(1px, 100%);
                background: var(--c-border);
                content: '';
            }
            p{
                background: var(--c-white);
                color: var(--c-font);
                font-size: 1.125rem /* 18/16 */;
                line-height: 1;
                padding: 10px 0;
                position: relative;
            }
        }
        .sns-login{
            padding: 30px 0;
            flex: 1;
            .intro-text{
                color: var(--c-font);
                font-size: 1.125rem /* 18/16 */;
                line-height: 1.2;
                margin-bottom: 30px;
            }
        }
        .sns-link{
            align-items: center;
            border-radius: 3px;
            display: flex;
            justify-content: center;
            padding: 10px;
            gap: 0 10px;
            width: 100%;
            +.sns-link{
                margin-top: 20px;
            }
            .ic{
                width: 30px;
                height: 30px;
                img{
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    margin: auto;
                }
            }
            span{
                font-size: 1.0625rem /* 17/16 */;
                text-transform: uppercase;
                line-height: 1.2;
                min-width: 180px;
            }
        }
        .sns-line{
            border: 1px solid #05b44a;
            &:hover{
                background: rgba(#05b44a, .07);
            }
            span{
                color: #05b44a;
            }
        }
        .sns-fb{
            border: 1px solid #4467ad;
            &:hover{
                background: rgba(#4467ad, .07);
            }
            span{
                color: #4467ad;
            }
        }
        .sns-g{
            border: 1px solid #e22f31;
            &:hover{
                background: rgba(#e22f31, .07);
            }
            span{
                color: #e22f31;
            }
        }
    }
    .register-flow{
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 70px;
        &:before{
            @include abs_pos($t:50%, $l:0);
            @include size(100%, 1px);
            background: #e5e5e5;
            content: '';
        }
        .flow{
            align-items: center;
            background: var(--c-white);
            display: flex;
            gap: 10px;
            padding: 0 20px;
            position: relative;
            &:first-child{
                padding-left: 0;
            }
            &:last-child{
                padding-right: 0;
            }
            &.active{
                .no{
                    background: var(--c-success);
                    p{
                        color: var(--c-white);
                    }
                }
            }
            .no{
                @include size(40px);
                @include flex($ai:center, $jfc:center);
                border-radius: 50%;
                background: url(../images/repeat-3.jpg);
                p{
                    color: var(--c-fontLight);
                    font-size: 1.25rem /* 20/16 */;
                    line-height: 1;
                    margin: 0;
                }
            }
            .flow-title{
                color: var(--c-font);
                font-size: 1.25rem /* 20/16 */;
                line-height: 1.2;
                margin: 0;
            }
        }
    }
    .term-limit{
        border: 1px solid #dfdfdf;
        padding: 10px;
        .term-content{
            height: 600px;
            overflow-y: auto;
            padding: 0 15px;
        }
    }
    .term-bottom-sec{
        margin-top: 10px;
    }
    .term-btn-row{
        display: flex;
        gap: 0 30px;
        justify-content: center;
        margin-top: 25px;
        a, button{
            border-radius: 3px;
            color: var(--c-white);
            display: block;
            font-size: 1.125rem /* 18/16 */;
            line-height: 1;
            padding: 15px 10px;
            text-align: center;
            flex: 1;
            max-width: 240px;
        }
        .btn-next{
            border: 1px solid var(--c-primary);
            background: var(--c-primary);
            &:hover{
                background: var(--c-white);
                color: var(--c-primary);
            }
        }
        .btn-prev{
            border: 1px solid #707070;
            background: #707070;
            &:hover{
                background: var(--c-white);
                color: #707070;
            }
        }
    }
    .register-final-sec{
        text-align: center;
        padding-top: 40px;
        .dec-img{
            display: block;
            margin: auto;
            width: 130px;
        }
        .content{
            margin-top: 30px;
        }
        .title{
            color: var(--c-primary);
            font-size: 1.375rem /* 22/16 */;
            font-weight: bold;
            line-height: 1;
            margin: 0;
        }
        .des{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.4;
            margin-bottom: 0;
            margin-top: 15px;
        }
    }
    .wsava-top-row{
        display: flex;
        justify-content: flex-end;
        gap: 15px 25px;
        margin-bottom: 30px;
        .filter-select{
            margin-bottom: 0;
            max-width: 160px;
        }
        .current-link{
            border-radius: 3px;
            border: 1px solid var(--c-primary);
            display: block;
            color: var(--c-primary);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            text-align: center;
            padding: 13px 10px;
            width: 160px;
            &:hover{
                background: var(--c-primary);
                color: var(--c-white);
            }
        }
        .keyword-form{
            margin-bottom: 0;
            margin-left: 0;
        }
    }
    .wsava-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        .wsava-item{
            @extend %clearStyle;
            .img-box{
                display: block;
            }
            .content{
                background: url(../images/repeat-2.jpg);
                padding: 25px 15px;
            }
            .top-des{
                border-bottom: 1px solid #e0e0e0;
                padding: 0 7px 15px;
                .wsava-title{
                    color: var(--c-font);
                    font-size: 1rem /* 16/16 */;
                    font-weight: bold;
                    line-height: 1.3;
                    &:hover{
                        color: var(--c-success);
                    }
                }
                .des{
                    @include lineLimit(1);
                    color: var(--c-fontLight);
                    font-size: 1rem /* 16/16 */;
                    line-height: 1;
                    margin-top: 8px;
                }
            }
            .bottom-des{
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding: 12px 7px 0;
                .date{
                    color: var(--c-primary);
                    font-size: .9375rem /* 15/16 */;
                    line-height: 1.2;
                }
                .more{
                    color: var(--c-primary);
                    font-size: 1rem /* 16/16 */;
                    &:hover{
                        color: var(--c-success);
                    }
                    svg{
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .download-table{
        .td{
            text-align: center;
            &:nth-child(3){
                text-align: left;
            }
        }
    }
    .board-table{
        .td{
            text-align: center;
            &:nth-child(1){
                text-align: left;
            }
        }
    }
    .board-top-row{
        align-items: center;
        display: flex;
        justify-content: flex-end;
        gap: 15px 25px;
        margin-bottom: 30px;
        .back-to-board{
            color: var(--c-primary);
            font-size: 1rem /* 16/16 */;
            text-decoration: underline;
            text-underline-offset: 3px;
            line-height: 1.2;
            &:hover{
                color: var(--c-success);
            }
        }
        .current-link{
            border-radius: 3px;
            border: 1px solid var(--c-primary);
            display: block;
            color: var(--c-primary);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            text-align: center;
            padding: 13px 10px;
            width: 160px;
            &:hover{
                background: var(--c-primary);
                color: var(--c-white);
            }
        }
        .keyword-form{
            margin-bottom: 0;
            margin-left: 0;
        }
    }
    .board-detail{
        --c-border: #dcdcdc;
        .top-sec{
            border: 1px solid var(--c-border);
            border-top: none;
        }
        .post-sec{
            border: 1px solid var(--c-border);
            padding: 50px 70px;
        }
        .post-row{
            border-top: 1px solid var(--c-border);
            display: grid;
            grid-template-columns: 275px auto;
        }
        .poster{
            border-right: 1px solid var(--c-border);
            .name{
                color: var(--c-font);
                font-size: 1.0625rem /* 17/16 */;
                font-weight: bold;
                line-height: 1;
                text-align: center;
                margin: 0;
                margin-bottom: 17px;
            }
            .info{
                color: var(--c-font);
                font-size: 1rem /* 16/16 */;
                line-height: 1.2;
                + .info{
                    margin-top: 10px;
                }
                span{
                    display: inline-block;
                }
            }
        }
        .post-head{
            background: #fdeeed;
            p{
                color: var(--c-font);
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1;
                padding: 15px 10px;
                text-align: center;
                margin: 0;
            }
        }
        .content-row{
            > div{
                padding: 25px 30px;
            }
        }
    }
    .pay-table{
        .td{
            text-align: center;
        }
    }
    .calendar-top-row{
        display: flex;
        justify-content: flex-end;
        gap: 15px 25px;
        margin-bottom: 30px;
        .filter-select{
            margin-left: inherit;
            margin-bottom: 0;
        }
    }
    .calendar-item{
        @extend %clearStyle;
        background: url(../images/repeat-2.jpg);
        display: grid;
        grid-template-columns: 200px auto;
        + .calendar-item{
            margin-top: 30px;
        }
        .content{
            align-items: center;
            display: grid;
            grid-template-columns: auto 120px;
            gap: 15px 25px;
            padding: 25px 55px;
        }
        .calendar-title{
            a{
                color: var(--c-font);
                display: inline-block;
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1;
                &:hover{
                    color: var(--c-success);
                }
            }
        }
        .des{
            @include lineLimit(1);
            color: var(--c-fontLight);
            font-size: 1rem /* 16/16 */;
            line-height: 1;
            margin-top: 7px;
        }
        .page-info-list{
            border-top: 1px solid #e3e3e3;
            padding-top: 15px;
            margin-top: 15px;
            margin-bottom: 0;
            .info-item{
                svg, span{
                    color: var(--c-primary);
                }
            }
        }
        .more-link{
            border-radius: 3px;
            background: var(--c-primary);
            display: block;
            color: var(--c-white);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1;
            padding: 15px 10px;
            text-align: center;
            max-width: 120px;
            width: 100%;
            &:hover{
                background: var(--c-success);
            }
            &.deadline{
                background: #bfbfbf;
                cursor: auto;
            }
        }
    }
    .event-list{
        @extend %clearStyle;
        margin-bottom: 40px;
    }
    .event-item{
        display: grid;
        grid-template-columns: 90px auto;
        padding-left: 15px;
        position: relative;
        &:before{
            @include abs_pos($t:6px, $l:0);
            @include size(6px);
            background: var(--c-success);
            content: '';
            transform: rotate(45deg);
        }
        + .event-item{
            margin-top: 10px;
        }
        p{
            color: var(--c-font);
            font-size: 1.0625rem /* 17/16 */;
            line-height: 1.3;
            + p{
                margin-top: 3px;
            }
        }
    }
    .apply-price-row{
        background: url(../images/repeat-3.jpg);
        padding: 35px 20px;
        .price-info{
            display: grid;
            grid-template-columns: auto 120px;
            gap: 0 20px;
            + .price-info{
                margin-top: 10px;
            }
            p{
                color: var(--c-font);
                font-size: 1.0625rem /* 17/16 */;
                line-height: 1;
            }
        }
        .final-info {
            p{
                font-size: 1.3125rem /* 21/16 */;
            }
            .price-fm{
                color: var(--c-danger);
            }
        }
        .title{
            text-align: right;
        }
    }
}

@media screen and (min-width: $w_tb_b) {
    .page-member{
        .reg-inquiry-table{
            .td:first-child{
                text-align-last: left;
            }
            .th, .td{
                text-align: center;
            }
            .t-time, .t-state{
                min-width: 120px;
            }
            .t-remark{
                min-width: 100px;
            }
        }
    }
}