/* -------------- index RWD --------------*/
@media (max-width: 1560px){ 
    .index{
        &-banner{
            .news-slider{
                left: 40px;
            }
        }
    }
}

@media (max-width: $w_pc_b){ 
    .index{
        &-medical{
            .frame:before{
                @include size(180px, 236px);
            }
            .medical-row{
                padding-left: 40px;
            }
        }
        &-links{
            .out-link-sec{
                width: 60vw;
            }
            .clinic-area-sec{
                max-width: 40vw;
            }
        }
    }
}

@media (max-width: $w_pc){ 
    .index{
        &-banner{
            .news-slider{
                width: 360px;
            }
            .news-item{
                padding: 0 5px;
            }
        }
        &-events{
            padding: 70px 0;
            .frame{
                grid-template-columns: 65% auto;
                gap: 30px 40px;
            }
            .event-head .ch{
                font-size: 1.625rem /* 26/16 */;
                padding: 15px;
                padding-top: 0;
            }
            .event-list{
                padding: 30px 0;
            }
            .btn-more{
                max-width: 180px;
            }
            .calendar-head .calendar-current{
                font-size: 1.6875rem /* 27/16 */;
                gap: 0 10px;
            }
            .calendar-row .cell p{
                font-size: 1.25rem /* 20/16 */;
            }
        }
        &-medical{
            .frame:before{
                @include size(180px, 236px);
            }
            .head-sec {
                .head .ch{
                    font-size: 1.625rem /* 26/16 */;
                    margin-top: 20px;
                }
                .more{
                    margin-top: 20px;
                }
            }
            .medical-row{
                padding-left: 40px;
            }
            .medical{
                .box{
                    align-items: flex-start;
                }
                .content{
                    padding-top: 0;
                }
            }
        }
        &-links{
            .out-link-sec{
                padding: 130px 40px;
            }
            .clinic-form{
                flex-direction: column;
                padding: 50px;
            }
        }
    }
}

@media (max-width: $w_pc_m){ 
    .index{
        &-banner{
            padding-bottom: 0;
            .news-slider{
                position: relative;
                padding: 40px;
                left: inherit;
                bottom: inherit;
                width: 100%;
            }
        }
        &-events{
            padding-top: 20px;
            .frame{
                grid-template-columns: auto 300px;
            }
            .event .event-title .event-name{
                font-size: 1.1875rem /* 19/16 */;
            }
            .calendar-head .calendar-current{
                font-size: 1.375rem /* 22/16 */;
            }
            .calendar-row .cell p{
                font-size: 1.125rem /* 18/16 */;
            }
        }
        &-links{
            .out-link-sec{
                padding: 130px 0;
            }
            .link-slider{
                padding: 0 40px;
            }
        }
        &-ads{
            gap: 6px;
            grid-template-columns: repeat(2, calc((100vw - 6px) / 2));
            .wide-ad{
                grid-column: 1 / -1;
            }
        }
    }
}

@media (max-width: $w_tb_b){ 
    .index{
        &-banner{
            .news-item .img-box .cover{
                .title{
                    font-size: .9375rem /* 15/16 */;
                }
            }
        }
        &-medical{
            .frame{
                padding-top: 80px;
                padding-bottom: 50px;
            }
            .frame:before{
                @include size(120px, 161px);
            }
            .medical{
                padding: 0;
                .box{
                    grid-template-columns: 200px auto;
                }
            }
        }
    }
}

@media (max-width: $w_tb){ 
    .index{
        &-banner{
            .news-slider{
                padding: 30px;
            }
        }
        &-events{
            .frame{
                grid-template-columns: 1fr;
            }
            .event-sec{
                order: 1;
            }
            .calendar-sec{
                @include maw(400px);
                margin-left: auto;
                margin-right: auto;
            }
        }
        &-medical{
            .frame{
                flex-wrap: wrap;
            }
            .head-sec{
                width: 100%;
                .more{
                    max-width: 150px;
                }
            }
            .medical-row{
                padding-left: 0;
                width: 100%;
            }
        }
        &-links{
            flex-direction: column;
            .out-link-sec{
                padding: 50px 0;
                width: 100%;
            }
            .clinic-area-sec{
                height: 300px;
                max-width: 100%;
            }
            .clinic-form{
                flex-direction: row;
            }
        }
    }
}

@media (max-width: $w_tb_m){ 
    .index{
        &-banner{
            .news-item .img-box .cover{
                padding: 10px;
                opacity: 1;
            }
        }
        &-events {
            padding-top: 0;
            padding-bottom: 50px;
            .event-head .ch{
                font-size: 1.375rem /* 22/16 */;
                padding: 10px;
                padding-top: 0;
            }
            .event{
                +.event{
                    margin-top: 20px;
                }
                .event-title .event-name{
                    font-size: 1.125rem /* 18/16 */;
                }
            }
            .calendar-row {
                gap: 10px;
                .cell p{
                    font-size: 1.0625rem /* 17/16 */;
                }
            }
        }
        &-medical{
            .frame{
                padding-top: 40px;
                padding-bottom: 40px;
            }
            .head-sec {
                .head {
                    .en{
                        font-size: 1.875rem /* 30/16 */;
                    }
                    .ch{
                        font-size: 1.375rem /* 22/16 */;
                        margin-top: 15px;
                    }
                }
                .more{
                    margin-top: 20px;
                }
            }
            .medical{
                .box{
                    grid-template-columns: 120px auto;
                }
                .content{
                    padding: 0;
                    padding-left: 10px;
                }
                .medical-title{
                    font-size: 1.125rem /* 18/16 */;
                }
                .des{
                    font-size: 1rem /* 16/16 */;
                    margin-top: 10px;
                }
                .more{
                    margin-top: 10px;
                }
            }
        }
        &-links{
            .clinic-form{
                padding: 30px 40px;
                flex-direction: column;
                .clinic-head, .select-style select{
                    font-size: 1.0625rem /* 17/16 */;
                }
            }
        }
    }
}

// @media (max-width: $w_mo){ }