/* -------------- container --------------*/
.frame{
	max-width: $w_pc_b;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-left: 40px;
	padding-right: 40px;
}

.video-frame {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	iframe, object, embed{
		@include size(100%);
		@include abs_pos($t:0, $l:0);
	}
}

.page-frame{
	padding: 55px 0 80px;
	.frame{
		align-items: flex-start;
		display: flex;
		gap: 40px 70px;
		max-width: 1680px;
	}
	aside{
		@extend %clearStyle;
		flex-shrink: 0;
		width: 280px;
	}
	.aside-head{
		align-items: center;
		display: grid;
		grid-template-columns: 130px auto;
		gap: 0 10px;
		margin-bottom: 15px;
		.aside-dec{
			display: block;
			width: 100%;
		}
		.ch{
			color: var(--c-primary);
			font-size: 1.75rem /* 28/16 */;
			font-weight: bold;
			line-height: 1;
			letter-spacing: 1px;
		}
		.en{
			color: var(--c-success);
			font-size: .9375rem /* 15/16 */;
			line-height: 1;
			margin-top: 10px;
			text-transform: uppercase;
		}
	}
	.aside-menu{
		li{
			background: url(../images/repeat-2.jpg);
			padding: 15px 20px 15px 60px;
			margin-bottom: 1px;
			&.active{
				background: url(../images/repeat-1.jpg);
				a{
					color: var(--c-primary);
					font-weight: bold;
				}
			}
		}
		a{
			color: var(--c-font);
			font-size: 1.0625rem /* 17/16 */;
			line-height: 1.2;
			&:hover{
				padding-left: 15px;
				position: relative;
				&:before{
					@include abs_pos($t:3px, $l:0);
					content: '\f0da';
					color: var(--c-success);
					font-size: 1rem /* 16/16 */;
					font-family: 'Font Awesome 6 Free';
					font-weight: bold;
					line-height: 1;
				}
			}
		}
	}
	.page-main-frame{
		width: 100%;
	}
}